.our-services{
    background-color: var(--dark-grey-80);
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding-bottom: 5rem;
}

.our-services > h1{ 
    margin-top: 8rem;
    margin-bottom: 4rem;
}

.keeping-track {
    background-image: url("../../../assets/images/working_girl.jpg");
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 40vh !important;
}

.timeline {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--dark-grey);
    padding: 1rem !important;
    padding-bottom: 10rem !important;
    height: unset !important;
}

.timeline > h1 {
    padding-top: 10rem;
    padding-bottom:10rem;
}

@media screen and (max-width: 1100px) {
    .our-services > h1 {
        font-size: 2rem;
        margin-top: 3rem;
        margin-bottom: 1rem;
    }
    .timeline > h1 {
        font-size: 2rem;
        padding: 0;
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
 }