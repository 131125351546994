.personell-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:1rem;
    width: 350px;
}
.personell-card-photo {
    border-radius: 50%;
    width: 10rem;
    height: 10rem;
}
.personell-card-name {
    font-size: 1.5rem;
}

.personell-card-text {
    text-align: center;
 }