.small-info-box-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.pre-title {
  font-size: 1.4rem;
  color: var(--dark-grey-60);
}

.title {
  font-size: 1.4rem;
  color: var(--dark-grey);
  font-weight: 600;
}

.text {
  font-size: 0.9rem;
  color: whtie;
}
