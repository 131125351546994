/* Sets the default height of sections */
.content-body > section:first-child {
  margin-top: var(--navbar-height);
}

.content-body > section {
  height: 100vh;
}

/* Small screens like phones */
@media screen and (max-width: 550px) {
  .content-body > section,
  .std-padding {
    padding-left: 5%;
    padding-right: 5%;
  }
  h1 {
    font-size: 2.7rem;
  }
  h2, .h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.6rem;
  }
  h4 {
    font-size: 1.1rem;
  }
  p {
    font-size: 0.7rem;
  }
}

/* Medium screens like tablets */
@media screen and (min-width: 550px) and (max-width: 1100px) {
  .content-body > section,
  .std-padding {
    padding-left: 5%;
    padding-right: 5%;
  }
  h1 {
    font-size: 4.1rem;
  }
  h2, .h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 2rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  p {
    font-size: 0.9rem;
  }
}

/* Big screens like laptops and monitors */
@media screen and (min-width: 1100px) {
  .content-body > section,
  .std-padding {
    padding-left: 15%;
    padding-right: 15%;
  }
  h1 {
    font-size: 6.4rem;
  }
  h2, .h2 {
    font-size: 4.2rem;
  }
  h3 {
    font-size: 4.2rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  p {
    font-size: 1rem;
  }
}

.color-dark-grey {
  color: var(--dark-grey);
}

.pointer {
  cursor: pointer;
}

.extra-light {
  font-weight: 200;
}