button.button {
  background-color: transparent;
  cursor: pointer;
  color: inherit;
  outline: inherit;
  border: solid 3px currentColor;
  font-weight: 700;
  border-radius: 3rem;
  padding: 0.2rem 1rem;
}

button.button:hover,
button.button:focus {
  border-color: var(--light-green-darker);
  color: var(--light-green-darker);
}
