.footer {
    height: fit-content !important;
    padding: 1.5rem 5px !important;
    background-color: var(--dark-grey);
    display: flex;
    justify-content: center;
    align-items: center;

  }
  @media screen and (max-width: 1100px) {
    .footer > p {
      font-size: 0.6rem;
    }
  }