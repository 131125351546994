.big-arrow {
  transform: translateX(-150%);
  transition: transform 0.7s;
  transition-delay: 0.3s;
  height: 100%;
  width: 100%;
}

.start {
  transform: translateX(0%);
}

.stop {
  transform: translateX(0%) !important;
}
