@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(../fonts/Poppins-Thin.ttf) format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(../fonts/Poppins-ThinItalic.ttf) format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(../fonts/Poppins-ExtraLight.ttf) format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(../fonts/Poppins-ExtraLightItalic.ttf) format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(../fonts/Poppins-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(../fonts/Poppins-LightItalic.ttf) format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(../fonts/Poppins-Italic.ttf) format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(../fonts/Poppins-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(../fonts/Poppins-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(../fonts/Poppins-MediumItalic.ttf) format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(../fonts/Poppins-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(../fonts/Poppins-SemiBoldItalic.ttf) format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(../fonts/Poppins-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(../fonts/Poppins-BoldItalic.ttf) format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(../fonts/Poppins-ExtraBold.ttf) format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(../fonts/Poppins-ExtraBoldItalic.ttf) format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(../fonts/Poppins-Black.ttf) format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(../fonts/Poppins-BlackItalic.ttf) format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica";
  src: local("Helvetica"), url(../fonts/Helvetica-LightOblique.ttf) format("truetype");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Helvetica";
  src: local("Helvetica"), url(../fonts/Helvetica-Light.ttf) format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Helvetica";
  src: local("Helvetica"), url(../fonts/Helvetica.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Helvetica";
  src: local("Helvetica"), url(../fonts/Helvetica-Oblique.ttf) format("truetype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Helvetica";
  src: local("Helvetica"), url(../fonts/Helvetica-BoldOblique.ttf) format("truetype");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Helvetica";
  src: local("Helvetica"), url(../fonts/Helvetica-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}
