@media only screen and (max-width: 1100px) {

  .timeline-container {
    border-left: 3px solid white;
    display: grid;
    grid-template-rows: repeat(5, auto);
    padding-top: 2rem;
    padding-bottom: 2rem;
    position: relative;
  }

  .timeline-container::after {
    position: absolute;
    content: "";
    width: 0px;
    height: 0px;
    border-top: 25px solid white;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    bottom: 0;
    left: 0;
    transform: translate(-12px, 50%);
  }

  .timeline-container > div > p {
    padding: 0.7rem;
  }

  .timeline-container > div > h4 {
    text-align: end;
    position: relative;
  }

  .timeline-container > div > h4::before {
    content: "";
    position: absolute;
    left: 0;
    height: 1rem;
    width: 1rem;
    background-color: white;
    border-radius: 50%;
    transform: translate(-50%, 0);
  }

  .timeline-title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;  
  }

  .timeline-line {
    width: 100%;
    background-color: white;
    height: 3px;
    transition: width 3s;
  }

  .not-in-view .timeline-line {
    width: 0 !important;
  }

  .timeline-title > span:last-child {
    white-space: nowrap;
  }
  .part-0 {
    grid-row: 1;
  }
  .part-1 {
    grid-row: 2;
  }
  .part-2 {
    grid-row: 4;
  }
  .part-3 {
    grid-row: 6;
  }
  .part-4 {
    grid-row: 3;
  }
  .part-5 {
    grid-row: 5;
  }
}

@media only screen and (min-width: 1100px) {
  .timeline-container {
    position: relative;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: auto;
    height: 60vh;
  }
  .timeline-container::before { 
    position: absolute;
    content: "";
    width: 0px;
    height: 0px;
    border-bottom: 10px solid transparent;
    border-top: 10px solid transparent;
    border-right: 10px solid transparent;
    border-left: 25px solid white;
    bottom: 50%;
    right: 0;
    transform: translate(45%, 50%);
  }
  .timeline-container::after { 
    content: "";
    position: absolute;
    height: 3px;
    width: 100%;

    left: 0;
    top:50%;
    transform: translateY(-50%);
    background-color: white;
  }

  .tc-hide {
    display: none;
  }
  
  .timeline-container > div {
    padding-left: 1rem;
    position: relative;
    align-self: end;
    transition: height 1.5s;
  }
  .timeline-container > div > p {
    opacity: 0;
    animation: fadeIn 2s;
    animation-delay: 1.8s;
    animation-fill-mode: forwards;
  } 

  .timeline-container > div > h4 {
    opacity: 0;
    animation: fadeIn 2s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
  }

  .timeline-container > div > h4 {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -150%);
  }

  .timeline-container > div::after {
    content: "";
    position: absolute;
    background-color: white;
    left: -3px;
    bottom: 0;
    width: 3px;
    height: 100%;
  }

  .timeline-container > div::before {
    content: "";
    position: absolute;
    background-color: white;
    bottom: 0;
    left: -1px;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    transform: translate(-50%, 50%);
  }
  .part-0 p,
  .part-1 p,
  .part-2 p,
  .part-3 p {
    padding-top: 0.7rem;
  }

  .part-4 p,
  .part-5 p {
    padding-bottom: 0.7rem;
  }

  .part-0 {
    margin-left: 10%;
    grid-row: 1;
    height: 80%;
  }
  .part-1 {
    margin-left: 10%;
    grid-row: 1;
    height: 100%;
  }
  .part-2 {
    margin-left: 20%;
    grid-row: 1;
    height: 70%;
  }
  .part-3 {
    margin-left: 30%;
    grid-row: 1;
    height: 90%;
  }

  /* The bottom part */
  .part-4 .timeline-title ,
  .part-5 .timeline-title {
    top: unset !important;
    bottom: 0 !important;
    transform: translate(-50%, 110%) !important;
  }
  .part-4 {
    margin-left: 65%;
    height: 80%;
    grid-column: span 2;
    width: 20rem;
  }
  .part-4,
  .part-5 {
    align-self: unset !important;
    display: flex;
    align-items: flex-end;
    max-width: 20rem;
  }
  .part-4::before,
  .part-5::before {
    top: 0;
    bottom: unset !important;
    transform: translate(-50%,-50%) !important;
  }
  .part-4::after {
    top: 0;
    bottom: unset;
  }
  .part-5::after {
    top: 0;
    bottom: unset;
  }
  .part-5 {
    height: 100%;
    width: 100%;
    margin-left: 30%;
  }

  div .not-in-view {
    height: 0 !important;
  }
}

.timeline-title {
  color: var(--green);
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}