body {
  margin: 0;
  font-family: Poppins, Arial, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

.poppins {
  font-family: Poppins, Arial, serif !important;
}

.helvetica {
  font-family: Helvetica, Arial, sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Helvetica, Arial, sans-serif;
}

/* CSS reset from https://www.joshwcomeau.com/css/custom-css-reset/ */

/*
1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}
/*
2. Remove default margin
*/
* {
  margin: 0;
}
/*
3. Allow percentage-based heights in the application
*/
html,
body {
  height: 100%;
}
/*
Typographic tweaks!
4. Add accessible line-height
5. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
/*
6. Improve media defaults
*/
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
/*
7. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
  font: inherit;
}
/*
8. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
  white-space: pre-line;
}
/*
9. Create a root stacking context
*/
#root,
#__next {
  isolation: isolate;
}

/* Own CSS reset */
html {
  scroll-behavior: smooth;
}

a,
a:-webkit-any-link {
  cursor: pointer;
  text-decoration: none;
  color: unset;
}

li {
  list-style: none;
}

/*Scrollbar*/
*::-webkit-scrollbar {
  width: 0.7rem;
  background-color: transparent;
}

*::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px var(--dark-grey); */
  background-color: var(--dark-grey);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--light-green-darker);
  border-radius: 4px;
}

.App,
#root,
.content-body {
  height: 100%;
}

@import "./variables.css";
@import "./fonts.css";
@import "./general.css";
