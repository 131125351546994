/* Small screen adoptations */
@media screen and (max-width: 850px) {
  nav.navbar {
    justify-content: center !important;
  }

  .navbar-container > .nav-links {
    position: absolute;
    overflow: hidden;
    width: 0;
    right: 0;
    height: calc(100vh - var(--navbar-height));
    padding-top: 5rem;
    top: var(--navbar-height);
    background-color: var(--dark-grey);
    display: flex;
    flex-direction: column;
    align-items: center !important;
    transition: width 0.5s;
  }

  .home-link {
    display: block !important;
  }

  .navbar-container > .nav-links > a {
    /* Remove all margins in the menu, so the items can be centered */
    margin: 0 !important;
    font-size: 1.6rem;
  }

  .navbar-container > .nav-links > button {
    margin-top: 5rem;
    align-self: auto !important;
  }

  .navbar-container > .nav-links.open {
    width: 100vw;
  }

  nav > .hamburger-button {
    display: flex !important;
  }
}

/* Hamburger button */
nav > .hamburger-button {
  display: none;
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  width: 2rem;
  height: 1.6rem;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  color: var(--light-grey);
}

nav > .hamburger-button:hover {
  color: var(--light-green-darker);
}

nav > .hamburger-button > div {
  width: 100%;
  border-top: 2px solid currentColor;
  transform-origin: left;
  opacity: 1;
  transition: all 0.7s;
}

/* Make hamburger menu a cross */
nav > .hamburger-button.open > .hamburger-button-top {
  transform: rotate(45deg);
}
nav > .hamburger-button.open > .hamburger-button-center {
  opacity: 0;
}
nav > .hamburger-button.open > .hamburger-button-bottom {
  transform: rotate(-45deg);
}

/* Navbar base styles */
nav.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: var(--dark-grey);
  height: var(--navbar-height);
  display: flex;
  align-items: center;
  color: var(--light-grey);
  opacity: 1;
  z-index: 1000;
  transition: opacity 1s;
}

.navbar-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5rem;
}

.home-link {
  display: none;
}

nav.navbar.hidden {
  opacity: 0;
}

/*
 * Opacity 0 fades the container, while this display none for all children
 * removes the children from the DOM. i.e. not clickable 
 */
nav.navbar.hidden * {
  display: none !important;
}

.nav-links {
  display: flex;
  gap: 0.8rem;
  align-items: flex-end;
}

.nav-links > a:hover,
.nav-links > a:focus {
  color: var(--light-green-darker);
}

.nav-links > a.active {
  color: var(--light-green-darker);
}

.nav-links > a:last-of-type {
  margin-right: 2rem;
}

.nav-button {
  align-self: flex-start;
}
