.our-history {
    background-image: url("../../../assets/images/coffee_girl.jpeg");
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding-bottom: 10rem;
}
.our-history::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 65%;
}

.our-history > p {
    text-align: center;
    max-width: 40rem;
    z-index: 1;
}

.our-history > h1 {
    font-weight: 700;
    color: white;
    z-index: 1;
    color: var(--green);
}

.our-name {
    padding: 0 !important; 
    display: flex;
    height: 65vh !important;
}

.our-name-logo{
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
}
.our-name-logo > img{
    width:50%;
}

.our-name-text{
    background-color: var(--dark-grey);
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 7rem;
}

.our-name-text > h2 {
    text-transform: uppercase;
}

.our-name-text > p {
    text-align: center;
}

.section-3-motto {
    position: relative;
    display: flex;
    justify-content: center;
    height: 65vh !important;
    background-image: url("../../../assets/images/abstract.jpeg");
    background-size: cover;
    z-index: -2;
}
.section-3-motto::after {
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: black;
    position: absolute;
    opacity: 50%;
    z-index: -1;
}

.section-3-motto > div {
    max-width: min(50rem, 90%);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.motto-text {
    font-size: 2.4rem;
    position: relative;
}
.motto-text::after {
    content: "\"";
    font-size: 10rem;
    position: absolute;
    top: 0;
    left: 0;
    transform:translate(-110%, -20%);
}
.section-4-who {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--dark-grey);
    padding-top: 6rem;
    padding-bottom: 6rem;
}

.section-4-who > h2 {
    text-align: center;
}

.who-card-container {
    display: flex;
    gap: 2rem;
    margin-top: 6rem;
}


@media screen and (max-width: 1100px) {
    .our-name {
        flex-direction: column;
    }
    .our-name > div {
        width: 100%;
        padding: 2rem;
    }
    .motto-text {
    font-size: 1.4rem;
    }

    .section-4-who {
        height: unset !important;
    }
    .who-card-container {
        flex-direction: column;
    }
    .personell-card-container {
    width: unset !important;
    }
  } 
