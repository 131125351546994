:root {
  --light-grey: #c4c4c4;
  --green: #24b19e;
  --dark-grey: #292929;
  --dark-grey-80: #43454a;
  --dark-grey-60: #5a656c;
  --dark-grey-40: #70878d;
  --light-green: #a9cec2;
  --light-green-darker: #89aaaa;

  --navbar-height: 10vh;
}
