.large-info-box-container::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 30%;
  background-color: var(--dark-grey);
  z-index: 0;
}

.large-info-box-container p,
.large-info-box-container h4{
  color: white;
}

.large-info-box-container p {
  font-weight: 200;
}

.large-info-box-container {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  height: 18rem;
}
.large-info-box-container.dynamic-size {
  height: auto;
  padding-top: 4rem;
  padding-bottom: 4rem;

}

.lib-inner-contaier{
  display: grid;
  grid-template-columns: 4rem auto;
  gap: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.large-info-box-container * {
  z-index: 1;
}

@media screen and (max-width: 750px) {
  .lib-inner-contaier {
    grid-template-columns: auto;
  }    
  .large-info-box-container {
    padding-top: 3rem;
    padding-bottom: 3rem;
    height: unset;
  }
  .lib-icon{
    position: absolute;
    right: 1rem;
    top: 1.4rem; 
    padding: 0;
    opacity: 50%;
    width: 4rem;
   }

   .lib-text-container {
     font-weight: 300;
   }
}

.arrow-container {
  position: absolute;
  height: 7rem;
  top: 50%;
  color: var(--green);
  transform: translate(-110%,-50%);
}

.lib-title {
  width:fit-content;
  position: relative;
}

.lib-divider {
  height: 0.35rem;
  width: 10%;
  background: var(--green);
  margin-top: 0.4rem;
  margin-bottom: 1rem;
}

.line-break {
  content: "";
  background-color: red;
}