/* Section 1 */
@media screen and (min-width: 1100px) {
  .home-section-1 {
    background-image: url("../../../assets/images/paper.jpeg");
  }
}

@media screen and (max-width: 1100px) {
  .home-section-1 {
    background-image: url("../../../assets/images/paper-mobile2.jpg");
  }
}

.home-section-1 {
  position: relative;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--dark-grey);
  height: 125vh !important;
}


/* Section 2 */
.home-section-2 {
  position: relative;
  display: flex;
  align-items: center;
  height: calc(100vh - calc(65.75px * 2)) !important; /*100vh - height of the svg wave*/
  background-color: var(--dark-grey);
}
.home-section-2 h2 {
  text-transform: uppercase;
}
.home-section-2 > div > p {
  font-weight: 200;
  font-size: 1.1rem;
  max-width: 450px;
  margin-top: 1.2rem;
}

/* Section 3 */
.content-body > .home-section-3 {
  position: relative;
  background-color: var(--light-green-darker);
  height: unset;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 5%;
  padding-top: 25vh;  
  padding-bottom: 10vh;
  gap: 8rem;
}

.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.max-60 {
  max-width: min(90%, 44rem);
}

.info-boxes {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 3rem;
} 

.home-section-3 .title ,
.home-section-3 .pre-title {
font-family: Helvetica, Arial, sans-serif;
}

/* Section 4 */
.home-section-4 {
  position: relative;
  background: none;
  height: auto !important;
  padding: 0 !important;
  padding-bottom: 5rem !important;
}
.home-section-4::after {
  /* Creating the video overlay */
  content: "";
  background-color: rgba(0,0,0,0.4);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
}

.section-4-content > h2{
  align-self: center;
  margin-bottom: 3rem;
  z-index: 1;
}

@media screen and (max-width: 1100px) {
  .section-4-content{
    padding-top: 4rem !important;
    padding-bottom: 0 !important;
  }
}

.section-4-content{
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  gap: 2rem;
  padding-top: 10rem;
  padding-bottom: 10rem;
}

#bubbles-video {
  object-fit: cover;
  height:100%;
  width: 100%;
  z-index: -1;
  position: absolute;
}

.line {
  content: "";
  border-top: 2px solid red;
  width: 90%;
}

.home-section-5 {
  justify-content: center;
  align-items: center;
  display: flex;
  background: var(--dark-grey);
  height: 70vh !important;
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.home-section-6 {    
  height: 90vh !important;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-image: url("../../../assets/images/paper-mobile2.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.section-6-button {
  margin-top: 3rem;
}

.typing-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.writing-text {
  text-align: center;
}

.writing-text .text-after {
  display: block;
  font-size: 0.7em;
}


/* 
@media screen and (max-width: 550px) {
}
@media screen and (min-width: 550px) and (max-width: 1100px) {
}
@media screen and (min-width: 1100px) {
} */