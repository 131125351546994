.wave-side-break {
    overflow: hidden;
    position: absolute;
    width:100%;
    transform-origin: center;
}

.top {
    transform: scale(1, -1);
    top: -1px;
}

.bottom {
    bottom: -1px;
}